<template>
  <div class="content-wrapper">
    <jzsy-header :title="title">
      <div slot="right" class="filter-btn">
        <div
          class="clean-date-btn"
          v-show="startDate || endDate"
          @click="cleanDate"
        >
          清除
        </div>
        <md-icon
          class="search-btn"
          name="search"
          size="lg"
          @click="showSearch"
          v-show="!searchShow"
        ></md-icon>
        <md-icon name="calendar" size="lg" @click="showCalendar"></md-icon>
      </div>
    </jzsy-header>
    <md-input-item
      class="search-input"
      v-show="searchShow"
      v-model="searchKeywords"
      type="text"
      placeholder="企业名称、信用代码"
      is-highlight
      clearable
      @change="keywordsSearch"
    >
      <md-icon name="search" slot="left" color="#9579E5"></md-icon>
      <label slot="right" @click="hideSearch">关闭</label>
    </md-input-item>
    <md-scroll-view
      ref="scrollView"
      class="scroll-view"
      :scrolling-x="false"
      immediate-check-end-reaching
      auto-reflow
      @end-reached="$_onEndReached"
    >
      <md-field>
        <div
          class="no-datas"
          v-show="pageInitFinished && (!datas || !datas.length)"
        >
          暂无数据
        </div>
        <md-cell-item
          arrow
          v-for="item in datas"
          :key="item.uuid"
          @click="clickItemHandle(item.uuid)"
        >
          <div class="item">
            <div class="enterprise-name">{{ item.enterpriseName }}</div>
            <div class="detail">
              <div class="matched-date">匹配时间：{{ item.matchedDate }}</div>
              <div class="matched-by">业务员：{{ item.staff }}</div>
            </div>
          </div>
        </md-cell-item>
      </md-field>
      <md-scroll-view-more slot="more" :is-finished="isFinished" />
    </md-scroll-view>
    <van-calendar
      v-model="calendarShow"
      type="range"
      :min-date="new Date(2020, 0, 1)"
      :max-date="new Date()"
      :default-date="defaultDate"
      title="选择起止日期"
      color="#7147B5"
      :max-range="366"
      range-prompt="日期跨度不能超过一年"
      @confirm="selectDateHandle"
    />
  </div>
</template>

<script>
import {
  ScrollView,
  ScrollViewRefresh,
  ScrollViewMore,
  CellItem,
} from "mand-mobile";
import ProductMatchApi from "@/api/productMatch";
import { mapState } from "vuex";

export default {
  components: {
    [ScrollView.name]: ScrollView,
    [ScrollViewMore.name]: ScrollViewMore,
    [ScrollViewRefresh.name]: ScrollViewRefresh,
    [CellItem.name]: CellItem,
  },
  data() {
    return {
      pageInitFinished: false,
      lastMinId: 0,
      datas: [],
      isFinished: false,
      searchShow: false,
      calendarShow: false,
      searchKeywords: "",
      startDate: null,
      endDate: null,
      searchTimer: null,
    };
  },
  computed: {
    ...mapState("login", ["isAdmin"]),
    defaultDate() {
      let now = new Date();
      return [new Date(now.getFullYear(), now.getMonth(), 1), new Date()];
    },
    title() {
      let str = "匹配记录";
      if (this.startDate && this.endDate) {
        str = `${this.startDate.getFullYear()}/${
          this.startDate.getMonth() + 1
        }/${this.startDate.getDate()}~${this.endDate.getFullYear()}/${
          this.endDate.getMonth() + 1
        }/${this.endDate.getDate()}`;
      } else if (this.starDate) {
        str = `${this.startDate.getFullYear()}/${
          this.startDate.getMonth() + 1
        }/${this.startDate.getDate()}~今天`;
      } else if (this.endDate) {
        str = `${this.endDate.getFullYear()}/${
          this.endDate.getMonth() + 1
        }/${this.endDate.getDate()}之前`;
      }
      return str;
    },
  },
  methods: {
    $_onEndReached() {
      if (this.isFinished) {
        return;
      }
      this.loadData(false);
    },
    loadData(refresh) {
      let lastId =
        !refresh && this.datas && this.datas.length
          ? this.datas[this.datas.length - 1].id
          : 0;
      let request = this.isAdmin
        ? ProductMatchApi.matchedRecordsOfOrg
        : ProductMatchApi.matchedRecordsOfStaff;
      request({
        lastMinId: lastId,
        keywords: this.searchKeywords,
        startDate: this.startDate ? this.startDate.getTime() : null,
        endDate: this.endDate ? this.endDate.getTime() : null,
      }).then((res) => {
        if (!refresh) {
          if (!res.data.length) {
            this.isFinished = true;
          } else {
            this.datas = this.datas.concat(res.data);
          }
        } else {
          this.isFinished = false;
          this.datas = res.data;
          if (!res.data.length) {
            this.isFinished = true;
          }
        }
        this.$refs.scrollView.finishLoadMore();
        this.pageInitFinished = true;
      });
    },
    clickItemHandle(uuid) {
      this.$router.push(`/product/match/result/${uuid}`);
    },
    showSearch() {
      this.searchShow = true;
    },
    hideSearch() {
      this.searchShow = false;
      if (this.searchKeywords) {
        this.searchKeywords = "";
        this.loadData(true);
      }
    },
    showCalendar() {
      this.calendarShow = true;
    },
    selectDateHandle(value) {
      this.startDate = value[0];
      this.endDate = value[1];
      this.loadData(true);
      this.calendarShow = false;
    },
    cleanDate() {
      this.startDate = null;
      this.endDate = null;
      this.loadData(true);
    },
    keywordsSearch() {
      clearTimeout(this.searchTimer);
      this.searchTimer = setTimeout(() => {
        this.loadData(true);
      }, 500);
    },
  },
};
</script>

<style scoped>
.filter-btn {
  margin-right: 40px;
  display: flex;
  align-items: center;
  height: 92px;
}
.clean-date-btn {
  margin-right: 30px;
}
.search-btn {
  margin-right: 30px;
}
.search-input {
  padding: 0 20px;
}
.scroll-view {
  height: calc(100vh - 100px);
}
.enterprise-name {
  font-size: 32px;
}
.detail {
  margin-top: 20px;
  display: flex;
  font-size: 24px;
}
.matched-by {
  margin-left: 40px;
}
.no-datas {
  text-align: center;
  color: #8a8a8a;
}
</style>